import { useRef, useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import useWindowSize from "@/hooks/useWindowSize";
import { fitIn } from "@/utils/imageHandler";
import ContentWishlistComponent from "../parts/ContentWishlistComponent";

const SpotlightCard = ({ data, cardHeight }) => {
  const [expand, setExpand] = useState(false);
  const windowSize = useWindowSize();

  function expandCard() {
    if (windowSize.width > 1024) {
      setExpand(true);
    }
  }
  function shrinkCard() {
    setExpand(false);
  }
  return (
    <div className='relative'>
      <div className='absolute top-1 right-1 z-20'>
        <ContentWishlistComponent
          item={data}
          content_type_name_prop={data.content_type.name}
          isDarkBackground={false}
        />
      </div>
      <Link legacyBehavior href={`/spotlights/${data.slug}`} passHref>
        <a
          className={`block relative aspect-[188/250] transition-transform hover:z-10 origin-center duration-300 lg:hover:z-10 lg:hover:scale-[110%] ${
            cardHeight ?? "h-full"
          }`}
        >
          <Image
            src={fitIn(data.thumbnail_url, 600)}
            objectFit='cover'
            layout='fill'
            alt='Image'
            className='transition-all duration-300'
          />
          <span className='absolute inset-0 bg-gradient-to-b from-[#23212E]/0 to-[#23212E]'></span>
          <div className='absolute bottom-0 left-0 w-full p-4 text-white'>
            <span className='inline-block mb-1 text-xs font-bold text-transparent md:mb-1.5 bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
              {data.content_type.name}
            </span>
            <p
              className={`font-medium font-display ${
                expand ? "text-xl leading-tight" : "line-clamp-3 leading-snug"
              }`}
            >
              {data.title}
            </p>
            {expand && (
              <>
                <p className='mt-2 text-xs leading-normal opacity-50 line-clamp-2'>
                  {data.meta_desc}
                </p>
                <p className='mt-5 text-xs'>
                  <span className='opacity-50'>Written by : </span>
                  <span className='font-medium'>{data.author?.name}</span>
                </p>
              </>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default SpotlightCard;
