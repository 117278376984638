import { useState, useContext } from "react";
import { useAtom } from "jotai";
import { loginPopup } from "@/Atoms";
import AppContext from "@/AppContext";
import useAuth from "@/hooks/auth";
import { BookmarkSimple } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const ContentWishlistComponent = ({ item, content_type_name_prop, isDarkBackground = true }) => {
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const { user } = useAuth();

  const {
    userContentWishlistIds,
    onRemoveContentWishlist,
    setWishlist,
  } = useContext(AppContext);

  const handleMyWishlistClick = (e, cat_id, content_id, content_type_name) => {
    e.stopPropagation();
    if (!user) {
      setOpenLoginPopup(true);
      return;
    }

    setWishlist(cat_id, content_id, 0, content_type_name_prop || content_type_name);
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={`rounded-full ${isDarkBackground ? '' : 'bg-black bg-opacity-60'}`}>
            {userContentWishlistIds && (
              <div className='flex items-center justify-center text-white p-2'>
                {userContentWishlistIds.includes(item.id) ? (
                  <button
                    type='button'
                    onClick={(e) => onRemoveContentWishlist(e, item.id)}
                  >
                    <BookmarkSimple weight='fill' color='#FFD700' className='text-2xl' />
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={(e) => handleMyWishlistClick(e, null, item.id, item.content_type)}
                  >
                    <BookmarkSimple weight='bold' className='text-2xl' />
                  </button>
                )}
              </div>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent side="top">
          {userContentWishlistIds.includes(item.id)
            ? "Remove from bookmarks"
            : "Bookmark your favorite content so you can always find it at your fingertips."}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default ContentWishlistComponent;
